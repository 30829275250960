import { useStaticQuery, graphql } from 'gatsby';

export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query NewsLatest {
				news: allDatoCmsNewsPost(
					limit: 3
					sort: { order: DESC, fields: meta___firstPublishedAt }
				) {
					posts: nodes {
						id: originalId
						slug

						meta {
							firstPublishedAt
						}
						posted: meta {
							at: firstPublishedAt
						}
						title
						image: featuredImage {
							alt
							data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
						}
						author {
							name
						}
					}
				}
			}
		`
	);
	return data.news;
};
