// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Article, H6, H1 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Article)(
	(props) => css`
		position: relative;
		margin: 6rem 0 0 0;
		padding: ${props.theme.padS} 0;
		overflow: hidden;

		${breakup.medium`
			padding: ${props.theme.padM} 0;
		`}

		${breakup.large`
			padding: ${props.theme.padL} 0;
		`}

		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			top: ${props.theme.padL};
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			${props.theme.grad(0, props.theme.bc4, `${props.theme.bc4}00`)}
		}
	`
);

export const Content = styled.div(
	(props) => css`
		position: relative;
		z-index: 2;

		display: flex;
		flex-flow: column wrap;
		align-items: flex-start;
		justify-content: flex-start;
	`
);

export const Title = styled(H1)(
	(props) => css`
		margin: 0 0 1.8rem 0;

		${breakup.smedium` margin: 0 0 2.4rem 0;`}
		${breakup.large` margin: 0 0 3.6rem 0; `}
	`
);

export const Details = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column-reverse wrap;
		align-items: flex-start;
		justify-content: flex-start;

		${breakup.large`
            flex-flow: row wrap;
            align-items: center;
        `}
	`
);

export const Author = styled.div(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;
	`
);

export const AuthorPic = styled.figure(
	(props) => css`
		overflow: hidden;
		border-radius: 100%;
		margin-right: 2.4rem;

		width: 3.6rem;
		height: 3.6rem;

		${breakup.smedium`
            width: 4.8rem;
            height: 4.8rem;
        `}

		${breakup.large`
            width: 6rem;
            height: 6rem;
        `}

		.gatsby-image-wrapper {
			width: 100% !important;
			height: 100% !important;
		}

		img {
			border-radius: 100%;
			width: 3.6rem;
			height: 3.6rem;

			${breakup.smedium`
            width: 4.8rem;
            height: 4.8rem;
        `}

			${breakup.large`
            width: 6rem;
            height: 6rem;
        `}
		}
	`
);

export const Grad = styled.span(
	(props) => css`
		${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}
		display: block;
		width: 6rem;
		height: 1px;
		margin: 0 0 2.4rem 0;

		${breakup.large` margin: 0 3.6rem; `}
	`
);

const sharedProps = (props) => css`
	span {
		font-weight: ${props.theme.reg};
	}
`;

export const AuthorName = styled(H6)(
	(props) => css`
		${sharedProps}
	`
);

export const Date = styled(H6)(
	(props) => css`
		${sharedProps}
		margin-bottom: 1.2rem;

		${breakup.large` margin: 0; `}
	`
);
