// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket, JacketSmall } from './styles';

// Component
// ------
function Heading({ content, isSmall }) {
	if (isSmall) return <JacketSmall>{content}</JacketSmall>;
	if (!isSmall) return <Jacket>{content}</Jacket>;
}

export default React.memo(Heading);
