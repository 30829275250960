// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
export const Jacket = styled.div(
	(props) => css`
		${breakup.large`
			padding-top: 1.2rem;
		`}
	`
);
