// Imports
// ------
import React from 'react';
import Seo from '@parts/Seo';
import NewsPost from '@parts/NewsPost';
import PageTransition from '@parts/PageTransition';
import { graphql } from 'gatsby';

// Component
// ------
function Post({ transitionStatus, entry, exit, data }) {
	// NOTE • Props
	const transProps = {
		status: transitionStatus,
		entry: entry,
		exit: exit,
	};

	return (
		<>
			<Seo content={data.post.seo} postOverride={data.post.title} />

			<PageTransition {...transProps}>
				<NewsPost data={data} />
			</PageTransition>
		</>
	);
}

export default Post;

// GraphQL
// ------
export const query = graphql`
	query Post($slug: String!) {
		post: datoCmsNewsPost(slug: { eq: $slug }) {
			# ANCHOR • Seo
			seo: seoMeta {
				title
				desc: description
				card: twitterCard
				image {
					url
				}
			}

			# ANCHOR • Global
			slug
			posted: meta {
				on: firstPublishedAt
			}

			# ANCHOR • Hero
			title
			author {
				name
				picture {
					alt
					data: gatsbyImageData(
						width: 60
						height: 60
						placeholder: BLURRED
						layout: FIXED
					)
				}
			}

			# ANCHOR • Featured Image
			featuredImage {
				alt
				data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
			}

			# ANCHOR • Page Builder
			pageBuilder: content {
				blocks {
					... on DatoCmsHeading {
						type: __typename
						id: originalId
						title
						smallBig
					}

					... on DatoCmsText {
						type: __typename
						id: originalId
						text
					}

					... on DatoCmsButton {
						type: __typename
						id: originalId
						label
						linkOffsite
						linkExternal
						linkInternal {
							... on DatoCmsNewsPage {
								slug
							}
							... on DatoCmsServicesPage {
								slug
							}
							... on DatoCmsContactPage {
								slug
							}
							... on DatoCmsCareersPage {
								slug
							}
							... on DatoCmsAboutPage {
								slug
							}
							... on DatoCmsHomePage {
								slug
							}
						}
					}
				}
			}
		}
	}
`;
