// Imports
// ------
import styled, { css } from 'styled-components';
import TransitionLink from 'gatsby-plugin-transition-link';
import { breakup } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Label = styled.span(
	(props) => css`
		${pStyles}

		color: ${props.isLight ? props.theme.white : props.theme.bc1};
		transition: ${props.theme.ease};
	`
);

export const Content = styled.span(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;

		background: ${props.isLight
			? `${props.theme.white}20`
			: `${props.theme.bc1}20`};
		padding: 1.5rem 2.4rem 1.6rem;
		border-radius: 3.6rem;
		transition: ${props.theme.ease};

		${breakup.large`
			&:hover {
				background: ${props.isLight ? `${props.theme.white}40` : props.theme.bc1};
			}
		`}

		svg {
			fill: none;
			stroke: ${props.isLight ? props.theme.white : props.theme.bc1};
			stroke-width: 4px;

			width: 1.2rem;
			height: 1.2rem;

			margin-left: 0.6rem;
			transition: ${props.theme.ease};
		}
	`
);

const sharedJacketStyles = (props) => css`
	display: inline-block;

	&:hover {
		${breakup.large`
				
			${Label} {
				color: ${props.theme.white};
			}

			svg {
				stroke: ${props.isLight ? props.theme.bc2 : props.theme.white};
			}
		`}
	}
`;

export const Jacket = styled(TransitionLink)(
	(props) => css`
		${sharedJacketStyles}
	`
);

export const JacketExt = styled.a(
	(props) => css`
		${sharedJacketStyles}
	`
);
