// Imports
// ------
import React from 'react';
import loadable from '@loadable/component';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

// Lazy
// ------
const Icon = loadable(() => import('@parts/Icons'));

// Styles
// ------
import { Jacket, JacketExt, Content, Label } from './styles';

// Component
// ------
function Inner({ label, isLight, noIcon }) {
	return (
		<Content isLight={isLight}>
			<Label isLight={isLight}>{label}</Label>
			{!noIcon && <Icon type='chevron-right' />}
		</Content>
	);
}

function Button({ label, to, isLight, isExternal, noIcon }) {
	// NOTE • MIXPANEL
	const mixpanel = useMixpanel();

	// NOTE • Click Handler
	const handleClick = () => {
		mixpanel.track(`Button Pressed (${to})`);
	};

	// NOTE • External Props
	const extProps = {
		href: to,
		'aria-label': `Go to ${to}`,
		target: '_blank',
		rel: 'noopener noreferrer',
		className: 'cursor-hover cursor-button',
	};
	// NOTE • Page Transtion Props
	const pageTrans = {
		to: `/${to}`,
		className: 'cursor-hover',
		exit: { length: 1 },
		entry: { length: 1, delay: 1 },
	};

	if (isExternal)
		return (
			<JacketExt {...extProps} onClick={handleClick}>
				<Inner label={label} isLight={isLight} noIcon={noIcon} />
			</JacketExt>
		);

	if (!isExternal)
		return (
			<Jacket {...pageTrans} onClick={handleClick}>
				<Inner label={label} isLight={isLight} noIcon={noIcon} />
			</Jacket>
		);
}

export default React.memo(Button);
