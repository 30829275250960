// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(P)(
	(props) => css`
		color: ${props.theme.bc4};
		opacity: 0.6;
		margin-bottom: 1.8rem;

		${breakup.large`
            margin-bottom: 2.4rem;
        `}
	`
);
