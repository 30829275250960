// Imports
// ------
import React from 'react';
import { Row, Column } from '@waffl';
import { useDato } from './data';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import {
	Jacket,
	Heading,
	Stories,
	Story,
	StoryOverflow,
	StoryTitle,
	StoryDetails,
	StoryImage,
	StoryAuthor,
} from './styles';

// Component
// ------
function LatestNews() {
	// NOTE • Data
	// ------
	const { posts } = useDato();

	// NOTE • Page Transtion Props
	const pageTrans = {
		exit: { length: 1 },
		entry: { length: 1, delay: 1 },
	};

	return (
		<Jacket pad>
			<Row isExpanded>
				<Column
					small={12}
					medium={10}
					pushOnMedium={1}
					large={10}
					pushOnLarge={1}
					mpad>
					<Heading>Latest news</Heading>
				</Column>
			</Row>

			<Row isExpanded isCollapsed>
				<Column small={12} large={10} pushOnLarge={1} mpad>
					<StoryOverflow>
						<Stories>
							{posts.map(({ id, title, image, author, slug }) => (
								<Story
									key={id}
									to={`/news/${slug}/`}
									className='cursor-hover'
									{...pageTrans}>
									<StoryImage className='cursor-hover'>
										<GatsbyImage image={image.data} alt={image.alt} />
									</StoryImage>

									<StoryDetails>
										<StoryTitle>{title}</StoryTitle>
										<StoryAuthor isBold>{author.name}</StoryAuthor>
									</StoryDetails>
								</Story>
							))}
						</Stories>
					</StoryOverflow>
				</Column>
			</Row>
		</Jacket>
	);
}

export default LatestNews;
