// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
function Text({ content }) {
	return <Jacket>{content}</Jacket>;
}

export default React.memo(Text);
