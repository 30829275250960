// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Article, Div } from '@tackl';

// Exports
// ------
export const Jacket = styled(Article)(
	(props) => css`
		position: relative;
		z-index: 2;
		background: ${props.theme.bc4};

		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: ${props.theme.white};

			${breakup.large`
				top: ${props.theme.padL};
				bottom: ${props.theme.padL};
			`}
		}
	`
);

export const Content = styled(Div)(
	(props) => css`
		position: relative;
		z-index: 2;
		width: 100%;

		${breakup.large`
			margin: ${props.theme.marL} 0;
		`}
	`
);

export const Image = styled.div(
	(props) => css`
		position: relative;
		margin: 0 2.4rem 0 0;
		z-index: 2;
		min-height: 48rem;
		max-height: 80rem;

		${breakup.large` margin: 0; `}

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: -2.4rem;
			right: -2.4rem;
			bottom: 0;

			${breakup.large` display: none; `}
		}

		&:before {
			z-index: 0;
			top: 0;
			background: ${props.theme.bc4};
		}

		&:after {
			z-index: 1;
			height: ${props.theme.padS};
			background: ${props.theme.white};
		}

		${breakup.large`
			position: sticky;
			top: 0;
			right: 0;
            height: 100vh;
			marign: 0;
        `}

		.gatsby-image-wrapper {
			position: relative;
			z-index: 2;
			width: 100%;
			height: 100%;

			img {
				border-radius: 0 12rem 0 0;

				${breakup.large`
				border-radius: 0;
			`}
			}
		}
	`
);
