// Imports
// ------
import React from 'react';
import Button from '@parts/Button';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
function Link({ isExt, linkExt, linkInt, label }) {
	if (isExt)
		return (
			<Jacket>
				<Button isExternal label={label} to={linkExt} />
			</Jacket>
		);

	if (!isExt)
		return (
			<Jacket>
				<Button label={label} to={linkInt.slug} />
			</Jacket>
		);
}

export default React.memo(Link);
