// Imports
// ------
import TransitionLink from 'gatsby-plugin-transition-link';
import styled, { css } from 'styled-components';
import { breakup, Section, H2, H6, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		${props.theme.grad(180, props.theme.bc4, `${props.theme.bc4}00`)}
		position: relative;
		z-index: 2;
	`
);

export const Heading = styled(H2)(
	(props) => css`
		margin: 0 0 2.4rem;
		${breakup.large` margin: 0 0 6rem; `}
	`
);

export const Stories = styled.div(
	(props) => css`
		display: flex;
		align-items: stretch;
		width: max-content;
		padding: 0 0 0 2.4rem;

		${breakup.medium`
			padding: 0 0 0 calc(8.333vw + 1.2rem);
		`}

		${breakup.large`
			display: grid;
			grid-gap: 3.6rem;
			grid-template-columns: repeat(3, 1fr);
			padding: 0 1.8rem;
			width: 100%;
		`}
	`
);

export const StoryOverflow = styled.div(
	(props) => css`
		${props.theme.noscrollbars}
		overflow: hidden;
		overflow-y: hidden;
		overflow-x: scroll;
		width: 100%;
		scroll-behavior: smooth;

		${breakup.large`
			overflow: hidden;
		`}

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			scrollbar-width: none;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			width: 0 !important;
			height: 0 !important;
			display: none;
		}
	`
);

export const StoryImage = styled.figure(
	(props) => css`
		position: relative;
		height: auto;
		overflow: hidden;
		padding-top: 112%;
		width: 100%;
		transition: ${props.theme.ease};

		${breakup.large`
			width: 100%;
			border-radius: 0 12rem 0 0;
		`}

		.gatsby-image-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: ${props.theme.ease};

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(
					to bottom,
					${props.theme.bc3}00 0%,
					${props.theme.bc3}80 100%
				);
				transform-origin: center bottom;
				transform: scaleY(0.5);
				opacity: 0;
				transition: ${props.theme.ease};
			}

			img {
				border-radius: 0 12rem 0 0;
				transition: ${props.theme.ease};
			}
		}
	`
);

export const StoryDetails = styled.div(
	(props) => css`
		flex: 1;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		align-items: flex-start;
	`
);

export const StoryTitle = styled(P)(
	(props) => css`
		position: relative;
		padding: 2.4rem 0 0;
		transition: ${props.theme.ease};
	`
);

export const StoryAuthor = styled(H6)(
	(props) => css`
		position: relative;
		margin: 2.4rem 0 0 0;
		padding: 2.4rem 0 0 0;
		transition: ${props.theme.ease};

		&:before {
			content: 'by ';
			font-weight: ${props.theme.reg};
		}

		&:after {
			${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 6rem;
			height: 1px;
		}
	`
);

export const Story = styled(TransitionLink)(
	(props) => css`
		height: 100%;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		align-items: flex-start;

		width: calc(100vw - 6rem);
		margin-right: 2.4rem;

		${breakup.medium`
			&:last-child {
				margin-right: calc(8.333vw  + 1.2rem);
			}
		`}

		${breakup.large`
			width: 100%;
			margin: 0;
		`}

		&:hover {
			${StoryTitle} {
				${breakup.large` opacity: 0.6; `}
			}

			${StoryImage} {
				.gatsby-image-wrapper {
					&:after {
						${breakup.large`
							transform: scaleY(1);
							opacity: 1;
						`}
					}
				}
			}
		}
	`
);
