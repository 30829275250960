// Imports
// ------
import React from 'react';
import Heading from './Heading';
import Text from './Text';
import Link from './Link';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import { Jacket, Content, Image } from './styles';

// Component
// ------
function PageBuilder({ image, content }) {
	// NOTE • Content Props
	const contentProps = {
		small: 12,
		medium: 10,
		pushOnMedium: 1,
		pullOnLarge: 4,
		large: 5,
		mpad: true,
	};

	const imageProps = {
		small: 12,
		medium: 10,
		pushOnMedium: 1,
		pushOnLarge: 7,
		large: 5,
	};

	return (
		<Jacket>
			<Row isExpanded isCollapsed isEqual>
				<Column {...imageProps}>
					<Image>
						<GatsbyImage image={image.data} alt={image.alt} />
					</Image>
				</Column>

				<Column {...contentProps}>
					<Row isExpanded>
						<Column mpad>
							<Content pad>
								{content.blocks.map((b) => {
									if (b.type === 'DatoCmsHeading')
										return (
											<Heading
												key={b.id}
												content={b.title}
												isSmall={b.smallBig}
											/>
										);

									if (b.type === 'DatoCmsText')
										return <Text key={b.id} content={b.text} />;

									if (b.type === 'DatoCmsButton')
										return (
											<Link
												key={b.id}
												label={b.label}
												isExt={b.linkOffsite}
												linkExt={b.linkExternal}
												linkInt={b.linkInternal}
											/>
										);
								})}
							</Content>
						</Column>
					</Row>
				</Column>
			</Row>
		</Jacket>
	);
}

export default PageBuilder;
