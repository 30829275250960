// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, H3, H5 } from '@tackl';

// Exports
// ------
const sharedProps = (props) => css`
	color: ${props.theme.bc4};
	margin-bottom: 1.8rem;

	${breakup.large`
        margin-bottom: 2.4rem;
    `}
`;

export const Jacket = styled(H3)(
	(props) => css`
		${sharedProps}
	`
);

export const JacketSmall = styled(H5)(
	(props) => css`
		${sharedProps}
	`
);
