// Imports
// ------
import React from 'react';
import Hero from './Hero';
import PageBuilder from './PageBuilder';
import LatestNews from '@parts/LatestNews';
import CallToAction from '@parts/CallToAction';

// Component
// ------
function NewsPost({ data }) {
	const { title, posted, author, featuredImage, pageBuilder } = data.post;

	return (
		<>
			<Hero title={title} author={author} date={posted.on} />
			<PageBuilder image={featuredImage} content={pageBuilder} />
			<LatestNews />
			<CallToAction />
		</>
	);
}

export default NewsPost;
