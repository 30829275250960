// Imports
// ------
import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Row, Column } from '@waffl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import {
	Jacket,
	Content,
	Title,
	Details,
	Author,
	AuthorPic,
	AuthorName,
	Date,
	Grad,
} from './styles';

// Component
// ------
const Hero = ({ title, author, date }) => {
	dayjs.extend(advancedFormat);

	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • Column Props
	const columnProps = {
		small: 12,
		medium: 10,
		pushOnMedium: 1,
		mpad: true,
	};

	// NOTE • Author Props
	const authorImg = {
		image: author.picture.data,
		alt: author.picture.alt,
	};

	// NOTE • Date
	const posted = dayjs(date).format('Do MMMM YYYY');

	return (
		<Jacket>
			<Row isExpanded>
				<Column {...columnProps}>
					<Content>
						{bp.large ? null : (
							<Date isBold>
								<span>Posted on </span>
								{posted}
							</Date>
						)}

						<Title>{title}</Title>

						<Details>
							<Author>
								<AuthorPic>
									<GatsbyImage {...authorImg} />
								</AuthorPic>

								<AuthorName isBold>
									<span>by </span>
									{author.name}
								</AuthorName>
							</Author>

							<Grad />

							{bp.large && (
								<Date isBold>
									<span>Posted on </span>
									{posted}
								</Date>
							)}
						</Details>
					</Content>
				</Column>
			</Row>
		</Jacket>
	);
};

export default Hero;
